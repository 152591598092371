import { Turnstile } from "@marsidev/react-turnstile"
import { useEffect, useState } from "react"

export type CaptchaInfo = {
  token: string
  ipAddress?: string
}

export const CaptchaManager = ({
  handleSetReady,
}: {
  handleSetReady: (isReady: boolean) => void
}) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleSuccess = () => {
    handleSetReady(true)
  }

  const handleShowCaptcha = () => {
    setIsVisible(true)
  }

  const handleCaptchaError = () => {
    handleShowCaptcha()
  }

  useEffect(() => {
    return () => {
      handleSetReady(false)
    }
  }, [])

  return (
    <div className="flex flex-row flex-1 justify-center">
      <Turnstile
        as="aside"
        siteKey={process.env.NEXT_PUBLIC_TURNSTILE_CAPTCHA as string}
        onSuccess={handleSuccess}
        onError={handleCaptchaError}
        onExpire={handleShowCaptcha}
        onUnsupported={handleShowCaptcha}
        onBeforeInteractive={handleShowCaptcha}
        options={{
          size: !isVisible ? "invisible" : "auto",
        }}
        scriptOptions={{
          appendTo: "body",
        }}
      />
    </div>
  )
}
