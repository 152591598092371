import LoadingSpinner from "@alwaysgeeky-games/shared-ui-components/LoadingSpinner"

export const LoadingSpinnerContainer = () => {
  return (
    <div
      data-testid="loading-spinner-container"
      className="flex flex-row flex-1 items-center justify-center"
    >
      <LoadingSpinner centered size="medium" />
    </div>
  )
}
