import { AlertIcon } from "@alwaysgeeky-games/shared-ui-components/Icons"

interface FormActionErrorProps {
  message: string
}

export const FormActionError = ({ message }: FormActionErrorProps) => {
  return (
    <div className="text-base mt-4 mb-4 flex flex-row align-top text-left text-error">
      <span className="mr-2 w-4 h-4 min-w-4">
        <AlertIcon className="fill-error mt-1" color="currentFill" />
      </span>
      <span>{message}</span>
    </div>
  )
}
