import classNames from "classnames"
import Link from "next/link"
import { ReactNode } from "react"

export const BUTTON_CLASSNAME =
  "inline-flex justify-center items-center gap-3 py-3 px-6 rounded-full text-base font-semibold transition duration-200 ease-in-out group bg-blue-500 text-white hover:bg-blue-700 disabled:bg-base-200 disabled:text-base-300 focus:outline-none focus-visible:ring focus-visible:ring-base-content focus:ring-opacity-20"

export type LinkContainerProps = {
  href: string
  target?: string
  className?: string
  children?: ReactNode
  variant?: "link" | "button" | undefined
  onClick?: (e: any) => void
}

export const CustomLink = ({
  href,
  target,
  children,
  className,
  variant = "link",
  onClick,
}: LinkContainerProps) => {
  return (
    <Link
      href={href}
      target={target}
      className={classNames("transition duration-200 ease-in-out", {
        "text-primary hover:text-blue-700": variant === "link",
        [`${BUTTON_CLASSNAME}`]: variant === "button",
        [`${className}`]: !!className,
      })}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}
