import SharedAppendedInput from "@alwaysgeeky-games/shared-ui-components/AppendedInput"
import {
  EyeIcon,
  EyeSlashIcon,
} from "@alwaysgeeky-games/shared-ui-components/Icons"
import { TextInputProps } from "@alwaysgeeky-games/shared-ui-components/TextInput/TextInput"
import { useCallback, useMemo, useState } from "react"

export const AppendedInput = (props: TextInputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const togglePasswordVisibility = useCallback(() => {
    if (props.type !== "password") {
      return
    }

    setIsPasswordVisible(prev => !prev)
  }, [])

  const inputType =
    isPasswordVisible && props.type === "password" ? "text" : props.type

  const icon = useMemo(
    () => (isPasswordVisible ? EyeSlashIcon : EyeIcon),
    [isPasswordVisible]
  )

  return (
    <SharedAppendedInput
      {...props}
      simple
      type={inputType}
      inputClassName="bg-transparent !text-black"
      wrapperClassName="border !border-gray-800 focus-within:!border-primary bg-transparent"
      icon={props.type === "password" ? icon : undefined}
      onIconClick={togglePasswordVisibility}
    />
  )
}
