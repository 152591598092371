import SharedTextInput from "@alwaysgeeky-games/shared-ui-components/TextInput"
import { TextInputProps } from "@alwaysgeeky-games/shared-ui-components/TextInput/TextInput"

export const TextInput = (props: TextInputProps) => {
  return (
    <SharedTextInput
      {...props}
      inputClassName="bg-transparent border border-gray-800 !text-black"
    />
  )
}
